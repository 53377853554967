import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { makeStyles, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, mergeClasses, shorthands, Spinner, tokens, } from "@fluentui/react-components";
import { AddRegular, CheckmarkFilled } from "@fluentui/react-icons";
import { userEditRolesDictionary } from "./roleUtils";
import { useAddRole } from "@features";
const useStyles = makeStyles({
    notAloneButton: {
        position: "relative",
        right: "0.3rem",
    },
    button: {
        color: tokens.colorNeutralForeground3,
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.border("none"),
        height: "2rem",
        minWidth: "1.5rem",
        maxWidth: "1.5rem",
        "& svg": {
            fontSize: "1rem",
        },
    },
});
const AddRolesButton = ({ roles, siteId, principalId, onAdd, dispatchMessage }) => {
    const sortedRolesDictionaryKeys = Object.keys(userEditRolesDictionary)
        .map(Number)
        .sort((keyA, keyB) => keyB - keyA);
    const styles = useStyles();
    const [addRoleMutation, { loading, data, error, succeeded }] = useAddRole();
    const [roleAdded, setRoleAdded] = useState(null);
    useEffect(() => {
        if (succeeded) {
            dispatchMessage(`${userEditRolesDictionary[data.roleId]} role added`, "success");
            const role = {
                principalId: data.principalId,
                siteId: data.siteId,
                roleId: data.roleId,
                tenantId: data.tenantId,
            };
            onAdd(role);
        }
    }, [succeeded, data]);
    const onAddRole = (roleId) => {
        setRoleAdded(roleId);
        addRoleMutation({
            principalId,
            siteId,
            roleId,
        });
    };
    useEffect(() => {
        if (error && roleAdded) {
            dispatchMessage(`Error adding ${userEditRolesDictionary[roleAdded]} role`, "error");
            setRoleAdded(null);
        }
    }, [error]);
    if (roles.length === Object.keys(userEditRolesDictionary).length) {
        return null;
    }
    return (_jsxs(Menu, { positioning: "below-end", children: [_jsx(MenuTrigger, { disableButtonEnhancement: true, children: _jsx(MenuButton, { className: mergeClasses(styles.button, roles.length > 0 && styles.notAloneButton), icon: loading ? _jsx(Spinner, { size: "extra-tiny" }) : _jsx(AddRegular, {}) }) }), _jsx(MenuPopover, { children: _jsx(MenuList, { hasIcons: true, children: sortedRolesDictionaryKeys.map((roleId) => {
                        const alreadyInRoles = roles.some((role) => role.roleId === roleId);
                        return (_jsx(MenuItem, { icon: alreadyInRoles ? _jsx(CheckmarkFilled, {}) : _jsx("div", {}), disabled: alreadyInRoles, content: userEditRolesDictionary[roleId], onClick: () => onAddRole(roleId) }, roleId));
                    }) }) })] }));
};
export default AddRolesButton;
