import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { makeStyles, mergeClasses, shorthands, Spinner, Title2, tokens } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import useGetPrincipals from "../hooks/useGetPrincipals";
import PrincipalsTable from "./PrincipalsTable";
import { gql, useMutation } from "@apollo/client";
import MessagePanel from "./MessagePanel";
import { useSelector } from "@lib/redux";
import FindUsers from "./FindUsers";
import AddSelectionButton from "./AddSelectionButton";
const useStyles = makeStyles({
    root: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        zIndex: 999999,
        backgroundColor: tokens.colorBackgroundOverlay,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modal: {
        position: "relative",
        width: "80vw",
        height: "80vh",
        "@media screen and (max-width: 1400px)": {
            width: "100vw",
            height: "95vh",
        },
        backgroundColor: tokens.colorNeutralBackground1,
        display: "flex",
        rowGap: "1rem",
        flexDirection: "column",
        ...shorthands.padding("2rem", "1rem", "0rem", "1rem"),
    },
    dismiss: {
        display: "flex",
    },
    dismissWithWarning: {
        justifyContent: "space-between",
    },
    dismissWithoutWarning: {
        justifyContent: "flex-end",
    },
    dismissButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: tokens.colorNeutralForeground2,
        width: "2rem",
        height: "2rem",
        ":hover": {
            backgroundColor: tokens.colorBrandBackground,
            color: tokens.colorNeutralBackground1,
        },
    },
    table: {
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginBottom: "1rem",
        overflowX: "auto",
        overflowY: "hidden",
        boxShadow: tokens.shadow8,
        flexGrow: 1,
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
    },
    spinner: {
        ...shorthands.margin("14%"),
    },
    messagePanel: {
        width: "fit-content",
    },
    messagePanelContainer: {
        position: "absolute",
        bottom: "0",
        width: "95.5%", // 100% - 1rem
        "@media screen and (max-width: 1400px)": {
            width: "100%",
        },
        display: "flex",
        justifyContent: "center",
        zIndex: 999999,
    },
});
const ADD_PRINCIPALS = gql `
  mutation AddPrincipalFromAzure($input: AddPrincipalFromAzureInput!) {
    addPrincipalFromAzure(input: $input) {
      principal {
        displayName
        id
        externalProviderInformation {
          azureObjectId
          azureTenantId
          providerName
        }
        infoLastUpdate
        principalType
        tenants
      }
    }
  }
`;
const AddPrincipals = ({ onDismiss, principalsInSystem }) => {
    const styles = useStyles();
    const principalTableRef = useRef(null);
    const tableContainer = useRef(null);
    const tenantId = useSelector((state) => state.tenant.id);
    const [selectedPrincipals, setSelectedPrincipals] = useState([]);
    const [principalItems, setPrincipalItems] = useState([]);
    const [filteredPrincipalItems, setFilteredPrincipalItems] = useState([]);
    const [showSelectedPrincipals, setShowSelectedPrincipals] = useState(false);
    const [selectedNotInFiltered, setSelectedNotInFiltered] = useState([]);
    const [showMessagePanel, setShowMessagePanel] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [addButtonDisabled, setAddButtonDisabled] = useState(true);
    const [messagePanelInfo, setMessagePanelInfo] = useState({ type: "success", message: "" });
    const [searchValue, setSearchValue] = useState("");
    const { principals, userAuthorization, appAuthorization, error, loading } = useGetPrincipals();
    const [addPrincipalsFromAzure, { loading: loadingAdd, client }] = useMutation(ADD_PRINCIPALS);
    const getAuthorizationMessage = (userAuth, appAuth) => {
        const typeOfPrincipals = !userAuth && !appAuth ? "" : !userAuth ? "users" : "applications";
        return `Principals ${typeOfPrincipals} can not be displayed. Need permissions to access to ${!userAuth ? "users" : ""} ${!userAuth && !appAuth ? "and" : ""} ${!appAuth ? "applications" : ""} in your organizations`;
    };
    const showAuthWarning = !loading && (!userAuthorization || !appAuthorization);
    const handleOnClickAddSelection = async () => {
        var _a;
        setAddButtonDisabled(true);
        if (selectedPrincipals.length > 0) {
            const principalInputs = selectedPrincipals.map((principal) => ({
                tenantIdOrKey: tenantId,
                azureTenantId: principal.azureTenantId,
                azureObjectId: principal.id,
                principalType: principal.principalType,
            }));
            try {
                await Promise.all(principalInputs.map((input) => addPrincipalsFromAzure({ variables: { input } })));
                setMessagePanelInfo({
                    type: "success",
                    message: `${principalInputs.length} Principal${principalInputs.length !== 1 ? "s" : ""} Added to tenant.`,
                });
                setHasChanges(true);
                setSelectedPrincipals([]);
                (_a = principalTableRef.current) === null || _a === void 0 ? void 0 : _a.addSelection();
            }
            catch (error) {
                setMessagePanelInfo({
                    type: "error",
                    message: "Error Adding Principals to tenant.",
                });
                console.error(error);
            }
            setShowMessagePanel(true);
        }
    };
    const onSelectionChange = (selectedItems) => {
        const selectedPrincipals = principalItems.filter((item) => selectedItems.includes(item.id) && !item.alreadyInTenant);
        setAddButtonDisabled(selectedPrincipals.length === 0);
        setSelectedPrincipals(selectedPrincipals);
        if (showSelectedPrincipals && selectedNotInFiltered.length > 0) {
            const selectedNotInFilteredNew = selectedPrincipals.filter((selected) => !filteredPrincipalItems.some((filtered) => filtered.id === selected.id));
            if (selectedNotInFilteredNew.length != selectedNotInFiltered.length) {
                setSelectedNotInFiltered(selectedNotInFilteredNew);
            }
        }
    };
    const onChangeSearchValue = (event) => {
        if (event.target.value === "") {
            setFilteredPrincipalItems([...principalItems]);
            setShowSelectedPrincipals(false);
            setSelectedNotInFiltered([]);
        }
        else {
            const filteredPrincipals = principalItems.filter((item) => item.displayName.toLowerCase().includes(event.target.value.toLowerCase()));
            const selectedNotInFiltered = selectedPrincipals.filter((selected) => !filteredPrincipals.some((filtered) => filtered.id === selected.id));
            setSelectedNotInFiltered(selectedNotInFiltered);
            setFilteredPrincipalItems(filteredPrincipals);
        }
        setSearchValue(event.target.value);
    };
    useEffect(() => {
        const principalsWithAlreadyInTenant = principals.map((principal) => ({
            ...principal,
            alreadyInTenant: principalsInSystem.some((principalInSystem) => principalInSystem.id.split("|")[2] === principal.id),
        }));
        setPrincipalItems([...principalsWithAlreadyInTenant]);
        setFilteredPrincipalItems([...principalsWithAlreadyInTenant]);
    }, [principals, principalsInSystem]);
    useEffect(() => {
        if (error) {
            setMessagePanelInfo({
                type: "error",
                message: "Error fetching principals from Azure.",
            });
            console.error(error);
            setShowMessagePanel(true);
        }
    }, [error]);
    const areAllPrincipalsAlreadyInTenant = principals.every((principal) => principalsInSystem.some((principalInSystem) => principalInSystem.id.split("|")[2] === principal.id));
    return (_jsx("div", { className: styles.root, children: _jsxs("div", { className: styles.modal, children: [_jsxs("div", { className: mergeClasses(styles.dismiss, showAuthWarning ? styles.dismissWithWarning : styles.dismissWithoutWarning), children: [showAuthWarning && (_jsx(MessagePanel, { type: "warning", message: getAuthorizationMessage(userAuthorization, appAuthorization), userAuth: userAuthorization, appAuth: appAuthorization })), _jsx("div", { className: styles.dismissButton, onClick: () => {
                                if (hasChanges) {
                                    client.refetchQueries({ include: ["principals"] });
                                }
                                onDismiss();
                            }, children: _jsx(Dismiss24Regular, {}) }), showMessagePanel && !loading && (_jsx("div", { className: styles.messagePanelContainer, children: _jsx("div", { className: styles.messagePanel, children: _jsx(MessagePanel, { type: messagePanelInfo.type, message: messagePanelInfo.message, onDismiss: () => setShowMessagePanel(false), userAuth: userAuthorization, appAuth: appAuthorization }) }) }))] }), _jsxs("div", { className: styles.header, children: [_jsx(Title2, { children: "Add Users" }), _jsx(AddSelectionButton, { disabled: addButtonDisabled, spinner: loadingAdd, numberSelectedPrincipals: selectedPrincipals.length, onClick: handleOnClickAddSelection })] }), _jsx("div", { children: _jsx(FindUsers, { visible: selectedNotInFiltered.length > 0, value: searchValue, showSelectedValue: showSelectedPrincipals, setShowSelectedPrincipals: setShowSelectedPrincipals, onChangeSearchValue: onChangeSearchValue }) }), _jsx("div", { className: styles.table, ref: tableContainer, children: loading ? (_jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner })) : (_jsx(PrincipalsTable, { filteredPrincipalItems: showSelectedPrincipals ? [...filteredPrincipalItems, ...selectedNotInFiltered] : filteredPrincipalItems, selectionChange: onSelectionChange, ref: principalTableRef, parentRef: tableContainer, hiddenHeaderSelector: areAllPrincipalsAlreadyInTenant })) })] }) }));
};
export default AddPrincipals;
