import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { makeStyles, Persona, Popover, PopoverSurface, PopoverTrigger } from "@fluentui/react-components";
import { UserCallout } from "../../userCallout";
import { useSelector } from "@lib/redux";
const useStyles = makeStyles({
    persona: {
        flexShrink: 1,
        display: "flex",
        paddingRight: "1.5em",
    },
    personaComponent: {
        cursor: "pointer",
    },
    mountPopover: {
        position: "absolute",
        top: "3.125rem",
    },
});
const PopoverMenu = ({ name, userName }) => {
    var _a;
    const styles = useStyles();
    const { tenant: tenant, appState: { isTenantSettingsPageActive }, } = useSelector((state) => state);
    const popoverCustomPosition = useRef(null);
    const positioningRef = useRef(null);
    useEffect(() => {
        var _a;
        if (popoverCustomPosition.current) {
            (_a = positioningRef.current) === null || _a === void 0 ? void 0 : _a.setTarget(popoverCustomPosition.current);
        }
    }, [isTenantSettingsPageActive]);
    return (_jsxs("div", { "data-testid": "header-persona", children: [_jsx("div", { className: styles.mountPopover, ref: popoverCustomPosition }), _jsx("div", { className: styles.persona, children: _jsxs(Popover, { positioning: { positioningRef }, children: [_jsx(PopoverTrigger, { children: _jsx(Persona, { className: styles.personaComponent, name: name, primaryText: "", avatar: { color: "colorful" } }) }), _jsx(PopoverSurface, { children: _jsx(UserCallout, { tenantName: (_a = tenant === null || tenant === void 0 ? void 0 : tenant.name) !== null && _a !== void 0 ? _a : "", name: name, email: userName }) })] }) })] }));
};
export default PopoverMenu;
