import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Subtitle2Stronger } from "@fluentui/react-components";
import { details as useStyles } from "@styles";
;
import { useUserRoles } from "@hooks";
const Main = ({ gateway, viaGateway }) => {
    const styles = useStyles();
    const { tenantAdmin } = useUserRoles();
    return (_jsxs("div", { className: styles.section, "data-testid": "gateway-details-main", children: [_jsx(Subtitle2Stronger, { className: styles.sectionTitle, children: viaGateway ? "Via Gateway" : "General" }), _jsxs("div", { className: styles.row, children: [_jsx("div", { className: styles.rowItemPropertyName, children: "Machine Name" }), _jsx("div", { children: gateway.machineName })] }), _jsx(Divider, {}), tenantAdmin && (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.row, children: [_jsx("div", { className: styles.rowItemPropertyName, children: "Process ID" }), _jsx("div", { children: gateway.processId })] }), _jsx(Divider, {}), _jsxs("div", { className: styles.row, children: [_jsx("div", { className: styles.rowItemPropertyName, children: "Version" }), _jsx("div", { children: gateway.version })] })] })), _jsx(Divider, {}), _jsxs("div", { className: styles.row, children: [_jsx("div", { className: styles.rowItemPropertyName, children: "ID" }), _jsx("div", { children: gateway.id })] }), _jsx(Divider, {})] }));
};
export default Main;
