import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles, Persona, tokens, Tooltip } from "@fluentui/react-components";
import { ShieldPersonRegular } from "@fluentui/react-icons";
import { createColumn } from "@components";
var UserRoles;
(function (UserRoles) {
    UserRoles[UserRoles["ServiceProvider"] = 0] = "ServiceProvider";
    UserRoles[UserRoles["TenantAdmin"] = 1] = "TenantAdmin";
    UserRoles[UserRoles["SiteAdmin"] = 2] = "SiteAdmin";
    UserRoles[UserRoles["JobDesigner"] = 3] = "JobDesigner";
    UserRoles[UserRoles["Consumer"] = 4] = "Consumer";
})(UserRoles || (UserRoles = {}));
const useStyles = makeStyles({
    lineHeight: {
        "& span:nth-child(2)": {
            lineHeight: "var(--lineHeightBase600)",
        },
    },
    tenantAdminShield: {
        color: tokens.colorCompoundBrandBackground,
    },
});
const createPersonaColumn = (columnId, headerText, showName) => {
    const createPersona = (principal, _) => {
        const styles = useStyles();
        const isTenantAdmin = principal.roleAssignments.some((role) => role.roleId === UserRoles.TenantAdmin);
        return (_jsx(Persona, { "data-testid": "persona-cell-item", className: styles.lineHeight, name: principal.displayName, primaryText: showName ? undefined : "", presence: isTenantAdmin
                ? {
                    icon: (_jsx(Tooltip, { content: "Tenant Admin", relationship: "label", hideDelay: 250, positioning: "before-bottom", children: _jsx(ShieldPersonRegular, { className: styles.tenantAdminShield, "data-testid": "persona-tenant-admin-icon" }) })),
                    className: styles.tenantAdminShield,
                }
                : undefined }));
    };
    return createColumn(columnId, headerText, createPersona, true);
};
export { createPersonaColumn };
