import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { details as useStyles } from "@styles";
;
import ConnectedSystem from "./ConnectedSystem";
import MainSection from "./MainSection";
const SystemLinkDetails = ({ systemLink }) => {
    const styles = useStyles();
    return (_jsxs("div", { className: styles.root, children: [_jsx(MainSection, { systemLink: systemLink }), _jsx(ConnectedSystem, { systemLink: systemLink })] }));
};
export default SystemLinkDetails;
