import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ApolloProvider } from "@apollo/client";
import { InteractionType } from "@lib/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { Spinner, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CustomNavigationClient, loginRequestScopes } from "@lib/msal-browser";
import { apolloClient } from "@lib/apolloClient";
import { HeaderBar, ErrorDisplay, ErrorMessages, PageContainer } from "@features";
import "./app.scss";
const authError = ({ error }) => (_jsx(ErrorDisplay, { error: { status: 400, message: error === null || error === void 0 ? void 0 : error.errorMessage } }));
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: tokens.colorNeutralBackground2,
        height: "100vh",
        width: "100vw",
        ...shorthands.margin(0),
        color: tokens.colorNeutralForeground2,
        fontFamily: tokens.fontFamilyBase,
        "@media screen and (max-width: 480px)": { fontSize: "0.625rem" },
        "@media screen and (max-width: 768px)": { fontSize: "0.875rem" },
        "@media screen and (max-width: 992px)": { fontSize: "0.875rem" },
        "@media screen and (max-width: 1400px)": { fontSize: "1rem" },
    },
    content: {
        flexGrow: 1,
        display: "flex",
        ...shorthands.overflow("hidden"),
    },
    spinner: {
        ...shorthands.margin("25%"),
    },
});
function App({ pca }) {
    const styles = useStyles();
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    const authRequest = {
        ...loginRequestScopes,
    };
    pca.setNavigationClient(navigationClient);
    const loadingComponent = () => _jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner });
    return (_jsx("div", { className: styles.root, children: _jsx(MsalProvider, { instance: pca, children: _jsx(MsalAuthenticationTemplate, { interactionType: InteractionType.Redirect, authenticationRequest: authRequest, errorComponent: authError, loadingComponent: loadingComponent, children: _jsxs(ApolloProvider, { client: apolloClient, children: [_jsx(HeaderBar, {}), _jsx("div", { className: styles.content, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/:tenantIdOrKey/*", element: _jsx(PageContainer, {}) }), _jsx(Route, { path: "/", element: _jsx(ErrorDisplay, { error: { message: ErrorMessages.invalidTenant } }) })] }) })] }) }) }) }));
}
export default App;
