import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Mail20Regular, Person20Regular } from "@fluentui/react-icons";
import { makeStyles, Text } from "@fluentui/react-components";
import { mergeClasses } from "@fluentui/react-components";
import { cardsBody } from "@styles";
;
import { PrincipalType } from "@types";
import APIManagement from "../../assets/APIManagement.svg";
const useStyles = makeStyles({
    icon: {
        width: "1.25rem",
        paddingTop: "0.3rem",
        paddingBottom: "0.3rem",
    },
});
const TenantAdminCardBody = ({ principal }) => {
    const styles = cardsBody();
    const stylesIcon = useStyles();
    const { email, principalType } = principal;
    const noEmail = "No email";
    return (_jsx("div", { className: mergeClasses(styles.cardBody, styles.bodyPadding), children: _jsxs("div", { className: styles.cardBodyItem, children: [_jsxs("div", { className: styles.line, children: [_jsx(Mail20Regular, {}), _jsx(Text, { className: mergeClasses(styles.text, !email && styles.noValue), children: email ? email : noEmail })] }), _jsxs("div", { className: styles.line, children: [principalType === PrincipalType.USER ? (_jsx(Person20Regular, {})) : (_jsx("img", { className: stylesIcon.icon, src: APIManagement, alt: "APIManagement logo" })), _jsx(Text, { children: principalType })] })] }) }));
};
export default TenantAdminCardBody;
