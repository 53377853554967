var _a, _b, _c, _d;
import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";
import { default as packageJson } from "../../../../package.json";
// Config object to be passed to Msal on creation
const msalLogLevel = process.env.ENV === "dev" ? LogLevel.Verbose : LogLevel.Warning;
const msalConfig = {
    auth: {
        clientId: (_a = process.env.REACT_APP_AZUREAD_CLIENT_ID) !== null && _a !== void 0 ? _a : "",
        authority: (_b = process.env.REACT_APP_AUTHORITY) !== null && _b !== void 0 ? _b : "",
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        cloudDiscoveryMetadata: "Cypress" in window ? (_c = sessionStorage.getItem("cloudDiscoveryMetadata")) !== null && _c !== void 0 ? _c : undefined : undefined,
        authorityMetadata: "Cypress" in window ? (_d = sessionStorage.getItem("authorityMetadata")) !== null && _d !== void 0 ? _d : undefined : undefined,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
        loggerOptions: {
            logLevel: msalLogLevel,
            loggerCallback: (level, message) => {
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    default:
                        // do nothing
                        return;
                }
            },
        },
    },
    telemetry: {
        application: {
            appName: packageJson.name,
            appVersion: packageJson.version,
        },
    },
};
export { msalConfig };
