import { jsx as _jsx } from "react/jsx-runtime";
import { shorthands, Text, Tooltip } from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import useElementResize from "../../hooks/useElementResize";
const useStyles = makeStyles({
    text: {
        ...shorthands.overflow("hidden"),
        display: "block",
        width: "100%",
    },
    textContainer: {
        width: "100%",
    },
});
const TextTooltip = ({ text }) => {
    const styles = useStyles();
    const { ref: textReference, overflow } = useElementResize();
    const textElement = (_jsx(Text, { ref: textReference, truncate: true, wrap: false, className: styles.text, children: text }));
    return (_jsx("div", { className: styles.textContainer, children: overflow ? (_jsx(Tooltip, { content: text, relationship: "description", positioning: "after", withArrow: true, children: textElement })) : (textElement) }));
};
export default TextTooltip;
