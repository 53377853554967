import { jsx as _jsx } from "react/jsx-runtime";
/* istanbul ignore file */
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "@lib/redux";
import { FluentProvider } from "@fluentui/react-components";
import { BrowserRouter as Router } from "react-router-dom";
import { tpgLightTheme } from "@styles";
import App from "./App";
import { msalInstance } from "@lib/msal-browser";
import { ErrorBoundary } from "@features";
import { store } from "@lib/redux";
const rootElement = document.getElementById("root");
if (rootElement === null)
    throw new Error("Root element not present in html");
const root = createRoot(rootElement);
root.render(_jsx(React.StrictMode, { children: _jsx(FluentProvider, { theme: tpgLightTheme, children: _jsx(ErrorBoundary, { children: _jsx(Provider, { store: store, children: _jsx(Router, { children: _jsx(App, { pca: msalInstance }) }) }) }) }) }));
