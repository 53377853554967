import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Textbox20Regular, Connected20Regular } from "@fluentui/react-icons";
import { Text, Tooltip } from "@fluentui/react-components";
import { makeStyles, mergeClasses } from "@fluentui/react-components";
import { cardsBody } from "@styles";
;
import { useElementResize } from "@hooks";
const useStyles = makeStyles({
    twoLineText: {
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflowY: "hidden",
        textOverflow: "ellipsis",
        width: "14.375rem",
        height: "2.25rem",
        overflowWrap: "anywhere",
    },
});
const SiteCardBody = ({ site }) => {
    const cardBodyStyles = cardsBody();
    const styles = useStyles();
    const { ref: textReference, overflow } = useElementResize();
    const systemLinksText = (systemLinksLength) => `${systemLinksLength} ${systemLinksLength === 1 ? "System Link" : "System Links"}`;
    const descriptionElement = (_jsx("div", { ref: textReference, className: styles.twoLineText, children: site.description }));
    return (_jsx("div", { className: mergeClasses(cardBodyStyles.cardBody, cardBodyStyles.bodyPadding), "data-testid": "site-card-body", children: _jsxs("div", { className: cardBodyStyles.cardBodyItem, children: [site.description && (_jsxs("div", { className: cardBodyStyles.line, "data-testid": "site-card-body-description", children: [_jsx("div", { children: _jsx(Textbox20Regular, {}) }), overflow ? (_jsx(Tooltip, { content: site.description, relationship: "description", positioning: "after", withArrow: true, children: descriptionElement })) : (descriptionElement)] })), _jsxs("div", { className: cardBodyStyles.line, "data-testid": "site-card-body-connection", children: [_jsx(Connected20Regular, {}), _jsx(Text, { className: cardBodyStyles.text, children: systemLinksText(site.systemLinks.length) })] })] }) }));
};
export default SiteCardBody;
