import { useState, useEffect } from "react";
import diff from "microdiff";
import { acquireAdminSilentToken } from "@lib/msal-browser";
import { PrincipalType } from "@types";
const EXPIRATION_TIME = 5 * 60 * 1000;
let expirationTimeSpan = 0;
let principalsSaved = [];
let userAuthorizationSaved = false;
let appAuthorizationSaved = false;
const getAzureId = (token) => {
    const arrayToken = token.accessToken.split(".");
    const decodedToken = JSON.parse(atob(arrayToken[1]));
    return decodedToken.tid;
};
const sortPrincipalsByName = (userFormatted, appsFormatted) => {
    const sortedPrincipals = [...userFormatted, ...appsFormatted].sort((a, b) => a.displayName.localeCompare(b.displayName));
    return sortedPrincipals;
};
/**
 * This hook retrieves all the principals from the Azure AD tenant and formats them for display in the UI.
 * In order to not do a request to Azure AD every time the component is rendered, the hook saves the principals
 * and only request the principals if the time has expired or the user has changed or the granted permissions have changed.
 *
 * @returns
 *   principals: The formatted principals to display in the UI
 *  userAuthorization: If the user has permissions to read users
 *  appAuthorization: If the user has permissions to read applications
 * error: If an error occurred while fetching the principals
 * loading: If the hook is still fetching the principals
 */
const useGetPrincipals = () => {
    const [principals, setPrincipals] = useState(principalsSaved);
    const [loading, setLoading] = useState(true);
    const [userAuthorization, setUserAuthorization] = useState(userAuthorizationSaved);
    const [appAuthorization, setAppAuthorization] = useState(appAuthorizationSaved);
    const [error, setError] = useState(null);
    useEffect(() => {
        const getPrincipals = async () => {
            let userToken = null;
            let appToken = null;
            let azureId = "";
            let canReadUsers = false;
            let canReadApps = false;
            let userFormatted = [];
            let appsFormatted = [];
            let sortedPrincipals = [];
            const getTokenFromUsers = async () => {
                try {
                    userToken = await acquireAdminSilentToken("user");
                    azureId = getAzureId(userToken);
                    canReadUsers = true;
                }
                catch (err) {
                    canReadUsers = false;
                }
            };
            const getTokenFromApps = async () => {
                try {
                    appToken = await acquireAdminSilentToken("app");
                    azureId = getAzureId(appToken);
                    canReadApps = true;
                }
                catch (err) {
                    canReadApps = false;
                }
            };
            const getUsersFromAzure = async () => {
                if (canReadUsers) {
                    try {
                        if (userToken) {
                            const usersResponse = await fetch("https://graph.microsoft.com/v1.0/users", {
                                method: "GET",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${userToken.accessToken}`,
                                },
                            });
                            const users = await usersResponse.json();
                            if (users.error) {
                                throw new Error(users.error.message);
                            }
                            userFormatted = users.value.map((user) => ({
                                id: user.id,
                                displayName: user.displayName,
                                email: user.userPrincipalName,
                                azureTenantId: azureId,
                                alreadyInTenant: undefined,
                                principalType: PrincipalType.USER,
                            }));
                        }
                    }
                    catch (err) {
                        console.error(err);
                        setError(err instanceof Error ? err.message : "An unknown error occurred getting users");
                    }
                }
            };
            const getAppsFromAzure = async () => {
                if (canReadApps) {
                    try {
                        if (appToken) {
                            const appsResponse = await fetch("https://graph.microsoft.com/v1.0/applications", {
                                method: "GET",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${appToken.accessToken}`,
                                },
                            });
                            const apps = await appsResponse.json();
                            if (apps.error) {
                                throw new Error(apps.error.message);
                            }
                            appsFormatted = apps.value.map((app) => {
                                var _a;
                                return ({
                                    id: app.id,
                                    displayName: app.displayName,
                                    email: (_a = app.homepage) !== null && _a !== void 0 ? _a : "",
                                    azureTenantId: azureId,
                                    alreadyInTenant: undefined,
                                    principalType: PrincipalType.SERVICE_PRINCIPAL,
                                });
                            });
                        }
                    }
                    catch (err) {
                        console.error(err);
                        setError(err instanceof Error ? err.message : "An unknown error occurred getting apps");
                    }
                }
            };
            // Get tokens for users
            await getTokenFromUsers();
            // Get tokens for applications
            await getTokenFromApps();
            if (canReadUsers || canReadApps) {
                if (Date.now() > expirationTimeSpan || expirationTimeSpan === 0) {
                    if (canReadUsers)
                        await getUsersFromAzure();
                    if (canReadApps)
                        await getAppsFromAzure();
                    sortedPrincipals = sortPrincipalsByName(userFormatted, appsFormatted);
                    principalsSaved = [...sortedPrincipals];
                    userAuthorizationSaved = canReadUsers;
                    appAuthorizationSaved = canReadApps;
                    expirationTimeSpan = Date.now() + EXPIRATION_TIME;
                }
                else {
                    if (canReadUsers && !userAuthorizationSaved) {
                        await getUsersFromAzure();
                        appsFormatted = [...principalsSaved];
                    }
                    if (canReadApps && !appAuthorizationSaved) {
                        await getAppsFromAzure();
                        userFormatted = [...principalsSaved];
                    }
                    if ((canReadUsers && !userAuthorizationSaved) || (canReadApps && !appAuthorizationSaved)) {
                        sortedPrincipals = sortPrincipalsByName(userFormatted, appsFormatted);
                        principalsSaved = [...sortedPrincipals];
                        userAuthorizationSaved = canReadUsers;
                        appAuthorizationSaved = canReadApps;
                    }
                    else {
                        sortedPrincipals = [...principalsSaved];
                    }
                }
            }
            if (diff(principals, sortedPrincipals).length > 0) {
                setPrincipals(sortedPrincipals);
            }
            if (userAuthorization !== canReadUsers) {
                setUserAuthorization(canReadUsers);
            }
            if (appAuthorization !== canReadApps) {
                setAppAuthorization(canReadApps);
            }
            setLoading(false);
        };
        getPrincipals();
    }, []);
    return { principals, userAuthorization, appAuthorization, error, loading };
};
export default useGetPrincipals;
