import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { Badge, Text, Title1, Tooltip, mergeClasses } from "@fluentui/react-components";
import { BoxEdit20Regular, CalendarSync20Regular, Eye20Regular, Server20Regular } from "@fluentui/react-icons";
import { Card } from "@components";
import { setAndApplyGatewayIdFilter, useDispatch } from "@lib/redux";
import { rootPage as useRootStyles, cardsBody as useStyles } from "@styles";
;
import NoGateways from "./NoGateways";
import { useUserRoles } from "@hooks";
const showState = (state) => {
    return state === "PROVISIONING" || state === "PROVISIONED" || state === "REGISTERED" ? true : false;
};
const systemsCount = (gateway, systems) => {
    const numberOfSystems = systems.filter((system) => system.viaGateway === gateway.id).length;
    return `${numberOfSystems} connected ${numberOfSystems === 1 ? "system" : "systems"}`;
};
const lastGatewayUpdate = (lastUpdate) => {
    return new Date(lastUpdate).toLocaleString("en", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
};
const shortLastGatewayUpdate = (lastUpdate) => {
    const date = new Date(lastUpdate);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()) {
        return `Today, ${date.toLocaleString("en", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        })}`;
    }
    else if (date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()) {
        return `Yesterday, ${date.toLocaleString("en", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        })}`;
    }
    return date.toLocaleString("en", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
};
const GatewayInfo = ({ tenantIdOrKey, gateway, systems }) => {
    const styles = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { tenantAdmin } = useUserRoles();
    return (_jsx("div", { className: styles.cardBody, children: _jsxs("div", { className: styles.cardBodyItem, children: [_jsxs("div", { className: mergeClasses(styles.line, styles.link), onClick: (e) => {
                        e === null || e === void 0 ? void 0 : e.preventDefault();
                        dispatch(setAndApplyGatewayIdFilter(gateway.id));
                        navigate(`/${tenantIdOrKey}/settings/systems`);
                    }, children: [_jsx(Server20Regular, {}), _jsx(Text, { className: styles.linkText, children: systemsCount(gateway, systems) })] }), tenantAdmin && (_jsxs("div", { className: styles.line, children: [_jsx(BoxEdit20Regular, {}), _jsx(Text, { className: styles.text, children: gateway.version })] })), _jsxs("div", { className: styles.line, children: [_jsx(CalendarSync20Regular, {}), _jsx(Tooltip, { content: lastGatewayUpdate(gateway.lastUpdated), withArrow: true, relationship: "label", positioning: "after", children: _jsx(Text, { children: shortLastGatewayUpdate(gateway.lastUpdated) }) })] }), showState(gateway.state) && (_jsx(Badge, { className: styles.badge, "data-testid": "card-gateway-status-text", shape: "rounded", children: gateway.state }))] }) }));
};
const GatewaysCards = ({ tenantIdOrKey, gateways, systems, openDetailsView, setGatewayToShowInDetailsView }) => {
    const styles = useStyles();
    const rootStyles = useRootStyles();
    const onClickOpenDetailsView = (gateway, systems) => {
        openDetailsView(true);
        const systemsViaGateway = systems.filter((system) => system.viaGateway === gateway.id);
        if (systemsViaGateway.length > 0) {
            setGatewayToShowInDetailsView({
                ...gateway,
                connectedSystems: systemsViaGateway,
            });
        }
        else {
            setGatewayToShowInDetailsView(gateway);
        }
    };
    const menuCard = (gateway, systems) => {
        return [
            {
                key: "gateway-menu-1",
                name: "Details",
                icon: _jsx(Eye20Regular, {}),
                onClick: () => {
                    onClickOpenDetailsView(gateway, systems);
                },
            },
        ];
    };
    return (_jsxs("div", { className: rootStyles.wrapper, children: [_jsx(Title1, { className: rootStyles.pageTitle, children: "Gateways" }), gateways.length === 0 ? (_jsx(NoGateways, {})) : (_jsx("div", { className: styles.container, children: gateways.map((gateway) => (_jsx("div", { className: styles.cardContainer, children: _jsx(Card, { headerText: gateway.machineName, onClickHeader: () => {
                            onClickOpenDetailsView(gateway, systems);
                        }, menuItems: menuCard(gateway, systems), children: _jsx(GatewayInfo, { tenantIdOrKey: tenantIdOrKey, gateway: gateway, systems: systems }) }, `Card-${gateway.id}`) }, gateway.id))) }))] }));
};
export default GatewaysCards;
