import { webLightTheme } from "@fluentui/react-components";
const tpgTokens = {
    colorPrincipal: "#D60B52",
    colorForegroundLink: "#6087C1",
};
const customLightTheme = {
    ...webLightTheme,
    colorNeutralForeground1: "#555", // overridden token
};
export { customLightTheme, tpgTokens };
