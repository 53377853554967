import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@fluentui/react-components";
import { setSelectedSite, useDispatch, useSelector } from "@lib/redux";
import RunJobButton from "./RunJobButton";
import { TabOptions } from "../utils/JobUtils";
import { SitesDropdown } from "@components";
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
    },
});
const JobsHeader = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const { tab } = useSelector((state) => state.jobs);
    const { sites } = useSelector((state) => state.tenant);
    const { selectedSite } = useSelector((state) => state.appState);
    const sitesOptions = sites ? sites.map((site) => { var _a, _b; return ({ text: (_a = site.name) !== null && _a !== void 0 ? _a : "", id: (_b = site.id) !== null && _b !== void 0 ? _b : "" }); }) : [];
    const selectSite = (value) => {
        var _a, _b;
        const newSelectedSite = sites === null || sites === void 0 ? void 0 : sites.find((site) => site.id === value);
        dispatch(setSelectedSite({ id: (_a = newSelectedSite === null || newSelectedSite === void 0 ? void 0 : newSelectedSite.id) !== null && _a !== void 0 ? _a : "", name: (_b = newSelectedSite === null || newSelectedSite === void 0 ? void 0 : newSelectedSite.name) !== null && _b !== void 0 ? _b : "" }));
    };
    return (_jsxs("div", { className: styles.root, children: [_jsx(SitesDropdown, { options: sitesOptions, defaultValueId: selectedSite.id, onOptionSelect: selectSite }), tab === TabOptions.JOBS && _jsx(RunJobButton, {})] }));
};
export default JobsHeader;
