import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { rootPage as useRootStyles, cardsBody as useStyles } from "@styles";
;
import { Title1 } from "@fluentui/react-components";
import { Eye20Regular } from "@fluentui/react-icons";
import { Card } from "@components";
import { UserRoles } from "@lib/redux";
import TenantAdminCardBody from "./TenantAdminCardBody";
const TenantAdminCards = ({ principals, openDetailsView }) => {
    const styles = useStyles();
    const rootStyles = useRootStyles();
    const onClickOpenDetailsView = (principal) => {
        openDetailsView(principal);
    };
    const menuCard = (principal) => {
        return [
            {
                key: "site-menu-1",
                name: "Details",
                icon: _jsx(Eye20Regular, {}),
                onClick: () => {
                    onClickOpenDetailsView(principal);
                },
            },
        ];
    };
    const tenantAdmins = principals.filter((principal) => principal.roleAssignments.some((role) => role.roleId === UserRoles.TenantAdmin));
    return (_jsxs("div", { className: rootStyles.wrapper, children: [_jsx(Title1, { className: rootStyles.pageTitle, "data-testid": "title", children: "Tenant Administrators" }), _jsx("div", { className: styles.container, children: tenantAdmins.map((tenantAdmin) => (_jsx("div", { className: styles.cardContainer, children: _jsx(Card, { headerText: tenantAdmin.displayName, headerBadge: true, onClickHeader: () => {
                            onClickOpenDetailsView(tenantAdmin);
                        }, menuItems: menuCard(tenantAdmin), children: _jsx(TenantAdminCardBody, { principal: tenantAdmin }) }) }, tenantAdmin.id))) })] }));
};
export default TenantAdminCards;
