import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Subtitle1 } from "@fluentui/react-components";
import { emptyBody as useStyles } from "@styles";
;
import emptySystemsImage from "../assets/emptySystems.svg";
const NoSystems = ({ showMessage = false }) => {
    const styles = useStyles();
    return (_jsxs("div", { className: styles.emptyContainer, "data-testid": "systems-empty", children: [_jsx("img", { className: styles.image, src: emptySystemsImage, alt: "No Systems", "data-testid": "systems-empty-image" }), showMessage && (_jsx(Subtitle1, { "data-testid": "systems-empty-message", children: "You don't have a system. Please add one." }))] }));
};
export default NoSystems;
