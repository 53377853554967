import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Badge, Button, makeStyles, shorthands, Spinner, tokens } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { useDeleteRole } from "@features";
import { userRolesDictionary } from "./roleUtils";
const useStyles = makeStyles({
    role: {
        display: "flex",
        alignItems: "center",
    },
    badge: {
        marginRight: "0.1rem",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    button: {
        color: tokens.colorNeutralForeground3,
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.border("none"),
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        height: "2rem",
        minWidth: "1.5rem",
        maxWidth: "1.5rem",
        "& svg": {
            fontSize: "1rem",
        },
    },
});
const Role = ({ role, dispatchMessage, onDelete }) => {
    const styles = useStyles();
    const [deleteRoleMutation, { loading, error, succeeded }] = useDeleteRole();
    useEffect(() => {
        if (succeeded) {
            dispatchMessage(`${userRolesDictionary[role.roleId]} role deleted`, "success");
            onDelete(role.roleId);
        }
    }, [succeeded]);
    useEffect(() => {
        if (error) {
            dispatchMessage(`Error deleting ${userRolesDictionary[role.roleId]} role`, "error");
        }
    }, [error]);
    return (_jsxs("div", { className: styles.role, children: [_jsx(Badge, { "data-testid": "role-cell-item-badge", className: styles.badge, shape: "rounded", color: "informative", size: "extra-large", children: userRolesDictionary[role.roleId] }), _jsx(Button, { icon: loading ? _jsx(Spinner, { size: "extra-tiny" }) : _jsx(DismissRegular, {}), className: styles.button, onClick: () => {
                    deleteRoleMutation({ roleId: role.roleId, principalId: role.principalId, siteId: role.siteId });
                } })] }));
};
export default Role;
