import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "@fluentui/react-components";
import { useState } from "react";
import { ErrorDisplay, ErrorMessages, NoSystems, SystemDetail, SystemsCards } from "@features";
import { rootPage as useStyles } from "@styles";
;
import { useParams } from "react-router-dom";
import { SystemKind } from "@types";
import { useSelector } from "@lib/redux";
import { Details } from "@components";
const QUERY = gql `
  query tenant($tenantIdOrKey: String!) {
    tenant(tenantIdOrKey: $tenantIdOrKey) {
      id
      name
      key
      systems {
        id
        name
        type
        viaGateway
        connectionInformation {
          key
          type
          value
        }
      }
      gateways {
        id
        version
        machineName
        processId
        lastUpdated
        state
        createdAt
        createdById
        createdByDisplayName
      }
    }
  }
`;
const nullSystem = {
    id: "",
    name: "",
    key: "",
    type: SystemKind.NULL,
    viaGateway: "",
    connectionInformation: [],
};
const Systems = () => {
    const [openDetailsView, setOpenDetailsView] = useState(false);
    const [systemToShowInDetailsView, setSystemToShowInDetailsView] = useState(nullSystem);
    const { tenantIdOrKey } = useParams();
    const { principalId } = useSelector((state) => state.userInfo);
    const styles = useStyles();
    const { loading, error, data } = useQuery(QUERY, {
        variables: { tenantIdOrKey: tenantIdOrKey },
    });
    let content;
    if (loading || !principalId) {
        content = _jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner });
    }
    else if (error) {
        content = _jsx(ErrorDisplay, { error: { message: error === null || error === void 0 ? void 0 : error.message } });
    }
    else if (data.tenant === null) {
        content = _jsx(ErrorDisplay, { error: { message: ErrorMessages.invalidTenant } });
    }
    else {
        content = (_jsx(SystemsCards, { tenantIdOrKey: data.tenant.key, systems: data.tenant.systems, gateways: data.tenant.gateways, openDetailsView: setOpenDetailsView, setSystemToShowInDetailsView: setSystemToShowInDetailsView }));
    }
    return (_jsxs("div", { className: styles.root, children: [_jsx(Details, { isOpen: openDetailsView, title: "System Details", onDismiss: () => {
                    setOpenDetailsView(false);
                }, children: _jsx(Details.Body, { children: _jsx(SystemDetail, { system: systemToShowInDetailsView }) }) }), _jsx("div", { className: styles.body, children: content })] }));
};
export { NoSystems };
export default Systems;
