/* istanbul ignore file */
import { combineReducers } from "@reduxjs/toolkit";
import { appStateReducer, jobsReducer, routeReducer, systemsFilterReducer, tenantsReducer, userInfoReducer, } from "./slices";
const rootReducer = combineReducers({
    appState: appStateReducer,
    jobs: jobsReducer,
    routePage: routeReducer,
    systemsFilter: systemsFilterReducer,
    tenant: tenantsReducer,
    userInfo: userInfoReducer,
});
export default rootReducer;
