import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import errorSVG from "../../assets/error.svg";
const useStyles = makeStyles({
    errorContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
    },
    messageContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: "2rem",
        fontWeight: 600,
        lineHeight: "1",
        textAlign: "left",
        position: "relative",
        top: "-13vh",
    },
    mainMessage: {
        fontSize: "15vw",
        color: tokens.colorBrandBackground,
        zIndex: 1,
    },
    subMessage: {
        fontSize: "5vw",
        color: tokens.colorNeutralForeground4,
        zIndex: 1,
    },
    description: {
        width: "fit-content",
        ...shorthands.padding("1rem"),
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        backgroundColor: tokens.colorNeutralBackground6,
        color: tokens.colorNeutralForeground4,
        zIndex: 1,
    },
    image: {
        position: "absolute",
        top: "0vh",
        right: "-20vw",
        width: "60vw",
    },
});
const AppStateError = ({ authorizedError = false }) => {
    const styles = useStyles();
    return (_jsx("div", { "data-testid": "error-sorry-page", className: styles.errorContainer, children: _jsxs("div", { className: styles.messageContainer, children: [_jsx("div", { className: styles.mainMessage, children: "Sorry" }), authorizedError ? (_jsx("div", { className: styles.subMessage, children: "You are not authorized" })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.subMessage, children: "Try again in a moment" }), _jsx("div", { className: styles.description, children: "Something went wrong..." })] })), _jsx("img", { className: styles.image, src: errorSVG, alt: "error" })] }) }));
};
export default AppStateError;
