import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { details as useDetailStyles } from "@styles";
;
import { Button, Input, Label, makeStyles, mergeClasses, MessageBar, MessageBarBody, MessageBarTitle, shorthands, useId, Subtitle2Stronger, Divider, ProgressBar, } from "@fluentui/react-components";
import { gql, useMutation } from "@apollo/client";
import { updateSites, useDispatch, useSelector } from "@lib/redux";
const useStyles = makeStyles({
    root: {
        ...shorthands.padding("1rem"),
        boxSizing: "border-box",
        height: "100%",
    },
    footer: {
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
    },
    footerButtons: {
        display: "flex",
        justifyContent: "flex-end",
        columnGap: "1rem",
    },
    inputConfirmation: {
        marginLeft: "0.375rem",
        display: "flex",
        flexDirection: "column",
        columnGap: "0.5rem",
    },
    messageRoot: {
        marginLeft: "0.375rem",
        flexGrow: 2,
        display: "flex",
        flexDirection: "column",
        rowGap: "1rem",
        justifyContent: "flex-end",
        ...shorthands.overflow("hidden"),
    },
    messageWrap: {
        whiteSpace: "break-spaces",
    },
    progressBar: {
        paddingTop: "0.6rem",
        height: "1rem",
    },
});
const DELETE_SITE = gql `
  mutation DeleteSite($input: DeleteSiteInput!) {
    deleteSite(input: $input) {
      siteId
    }
  }
`;
const SiteDelete = ({ site, onCancel }) => {
    const detailsStyles = useDetailStyles();
    const styles = useStyles();
    const [disableButton, setDisableButton] = useState(true);
    const message = `Deleting this site will also remove all system links and jobs associated with it. This action cannot be undone.`;
    const warningMessage = (_jsx(MessageBar, { intent: "warning", className: styles.messageWrap, "data-testid": "site-delete-warning-message", children: _jsx(MessageBarBody, { children: message }) }));
    const [messages, setMessages] = useState([warningMessage]);
    const { sites, id: tenantId } = useSelector((state) => state.tenant);
    const dispatch = useDispatch();
    const [deleteMutation, { data, loading, error, client, reset }] = useMutation(DELETE_SITE);
    const inputId = useId("confirm-delete-input");
    const onDelete = () => {
        if (messages.length > 1) {
            setMessages([warningMessage]);
        }
        deleteMutation({ variables: { input: { siteIdOrName: site.id, tenantIdOrKey: tenantId } } });
    };
    const onChangeInput = (event) => {
        setDisableButton(event.target.value !== site.name);
    };
    useEffect(() => {
        if (error) {
            setDisableButton(false);
            const errorMessage = (_jsx(MessageBar, { intent: "error", className: styles.messageWrap, children: _jsxs(MessageBarBody, { children: [_jsx(MessageBarTitle, { children: "Delete error" }), error.message] }) }));
            setMessages([errorMessage, ...messages]);
        }
    }, [error]);
    useEffect(() => {
        var _a;
        if (data && data["deleteSite"] && data["deleteSite"]["siteId"]) {
            const updatedSites = (_a = sites === null || sites === void 0 ? void 0 : sites.filter((s) => s.id !== site.id)) !== null && _a !== void 0 ? _a : [];
            dispatch(updateSites(updatedSites));
            client.refetchQueries({ include: ["getSites"] });
            onCancel();
        }
    }, [data]);
    return (_jsxs("div", { className: mergeClasses(detailsStyles.root, styles.root), children: [_jsxs("div", { className: detailsStyles.section, children: [_jsx(Subtitle2Stronger, { children: "Details" }), _jsxs("div", { className: detailsStyles.row, children: [_jsx("div", { className: detailsStyles.rowItemPropertyName, children: "Name" }), _jsx("div", { className: detailsStyles.rowItemPropertyValueWrap, "data-testid": "site-delete-row-site-name-value", children: site.name })] }), _jsx(Divider, {})] }), _jsx("div", { className: styles.messageRoot, children: messages }), _jsxs("div", { className: styles.footer, children: [_jsxs("div", { className: styles.inputConfirmation, children: [_jsxs(Label, { htmlFor: inputId, size: "medium", "data-testid": "site-delete-label-message", children: ["Enter the site name \"", site.name, "\" to confirm deletion"] }), _jsx(Input, { id: inputId, type: "text", size: "medium", onChange: onChangeInput, "data-testid": "site-delete-input-confirm" })] }), _jsx("div", { className: styles.progressBar, children: loading && _jsx(ProgressBar, { max: 42 }) }), _jsxs("div", { className: styles.footerButtons, children: [_jsx(Button, { disabled: disableButton, appearance: "primary", onClick: onDelete, "data-testid": "site-delete-primary-button", children: "Delete" }), _jsx(Button, { onClick: onCancel, "data-testid": "site-delete-cancel-button", children: "Cancel" })] })] })] }));
};
export default SiteDelete;
