import { fetchUploadJobsDefinitions } from "@services/api/api";
import { LOAD_FILE_ERRORS } from "@services/api/config";
import { ClientError, ServerError } from "@services/api/utils/fetchWithRetry";
/**
 * Upload files to the system
 * @param files array of Files
 * @param tenantIdOrKey Tenant id or key
 * @param siteIdOrName Site id or name
 * @returns
 */
const uploadJobs = async (files, tenantIdOrKey, siteIdOrName) => {
    if (!tenantIdOrKey)
        return Promise.reject({ status: 400, message: "Tenant's ID or key must be specified" });
    if (!siteIdOrName)
        return Promise.reject({ status: 400, message: "Site's ID or name must be specified" });
    if (files.length === 0)
        return Promise.reject({ status: 400, message: "The files to upload should have at least one item" });
    const formData = new FormData();
    for (let file of files)
        formData.append("files", file);
    try {
        return await fetchUploadJobsDefinitions(tenantIdOrKey, siteIdOrName, formData);
    }
    catch (error) {
        if (error instanceof ClientError && error.status === LOAD_FILE_ERRORS.FILE_UPDATED) {
            return Promise.reject({
                status: error.status,
                message: files.length > 1
                    ? "Some job files were updated. Please, select job files again"
                    : "The job file was updated. Please, select the job file again",
            });
        }
        else if (error instanceof ClientError || error instanceof ServerError) {
            return Promise.reject({ status: error.status, message: error.message });
        }
        else {
            return Promise.reject(error);
        }
    }
};
export { uploadJobs };
