import { jsx as _jsx } from "react/jsx-runtime";
import { createTableColumn, TableCellLayout } from "@fluentui/react-components";
import HeaderCell from "./ColumnHeader";
const createColumn = (columnId, headerText, renderCellGenerator, sortable = false, badge = false) => {
    return createTableColumn({
        columnId: columnId,
        compare: sortable
            ? (a, b) => {
                const firstElementToCompare = a[columnId];
                const secondElementToCompare = b[columnId];
                let compare;
                switch (typeof firstElementToCompare) {
                    case "string":
                        compare = firstElementToCompare.localeCompare(secondElementToCompare);
                        break;
                    case "number":
                        compare = firstElementToCompare > secondElementToCompare ? 1 : -1;
                        break;
                    default:
                        compare = firstElementToCompare > secondElementToCompare ? 1 : -1;
                        break;
                }
                return compare;
            }
            : undefined,
        renderHeaderCell: (data) => {
            const width = data;
            return (_jsx(TableCellLayout, { children: _jsx(HeaderCell, { text: headerText, badge: badge, width: width }) }));
        },
        renderCell: (item) => renderCellGenerator(item, columnId),
    });
};
export { createColumn };
