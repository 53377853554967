import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Warning12Regular } from "@fluentui/react-icons";
import { Input } from "@components";
import { useSelector } from "@lib/redux";
const InputName = ({ onChange, type, value, initialValue }) => {
    const sites = useSelector((state) => state.tenant.sites);
    const [valueInputName, setValueInputName] = useState(value);
    useEffect(() => {
        setValueInputName(value);
    }, [value]);
    const isURIFriendly = (str) => {
        const uriFriendlyPattern = /^[a-zA-Z0-9-]+$/;
        const invalidChars = str
            .split("")
            .filter((char) => !uriFriendlyPattern.test(char))
            .map((char) => (char === " " ? "space" : char))
            .filter((char, index, self) => self.indexOf(char) === index);
        return { isValid: invalidChars.length === 0, invalidChars };
    };
    const onValidation = (valueToValidate, setValidation) => {
        const { isValid, invalidChars } = isURIFriendly(valueToValidate);
        if (!isValid) {
            setValidation({
                message: "Name contains non-URI friendly characters: " + invalidChars,
                icon: _jsx(Warning12Regular, {}),
                state: "warning",
            });
            return false;
        }
        if (type === "add" || (type === "edit" && (initialValue === null || initialValue === void 0 ? void 0 : initialValue.toLowerCase()) !== (valueToValidate === null || valueToValidate === void 0 ? void 0 : valueToValidate.toLowerCase()))) {
            if (sites === null || sites === void 0 ? void 0 : sites.some((site) => site.name.toLowerCase() === valueToValidate.toLowerCase())) {
                setValidation({
                    message: "Site with this name already exists",
                    icon: _jsx(Warning12Regular, {}),
                    state: "warning",
                });
                return false;
            }
        }
        return true;
    };
    return (_jsx(Input, { label: "Name", type: "text", value: valueInputName, maxLength: 25, required: true, onChange: onChange, onValidation: onValidation, "data-testid": "input-name" }));
};
export default InputName;
