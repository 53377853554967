import { jsx as _jsx } from "react/jsx-runtime";
import { Database20Regular, Server20Regular } from "@fluentui/react-icons";
import logoAzureDevOps from "../assets/logoAzureDevOps.svg";
import logoMSDataverse from "../assets/logoMSDataverse.svg";
import logoSAP from "../assets/logoSAP.svg";
import { SystemKind } from "@types";
import { makeStyles } from "@fluentui/react-components";
const useStyles = makeStyles({
    icon: {
        height: "1.25rem",
    },
    sap: {
        width: "1.25rem",
        paddingTop: "0.3rem",
        paddingBottom: "0.3rem",
    },
});
const SystemIcon = ({ type }) => {
    const styles = useStyles();
    let icon;
    switch (type) {
        case SystemKind.Database:
            icon = _jsx(Database20Regular, {});
            break;
        case SystemKind.DevOps:
            icon = _jsx("img", { className: styles.icon, src: logoAzureDevOps, alt: "Azure DevOps logo" });
            break;
        case SystemKind.MSDataverse:
            icon = _jsx("img", { className: styles.icon, src: logoMSDataverse, alt: "MSDataverse logo" });
            break;
        case SystemKind.SAP:
            icon = _jsx("img", { className: styles.sap, src: logoSAP, alt: "SAP logo" });
            break;
        default:
            icon = _jsx(Server20Regular, {});
            break;
    }
    return icon;
};
export default SystemIcon;
