import { SystemKind } from "@types";
const getSystemGroups = (withAll = true) => {
    return Object.values(SystemKind)
        .filter((type) => type !== SystemKind.NULL && (withAll || type !== SystemKind.ALL))
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
};
const getFormattedGroupName = (systemGroup) => {
    switch (systemGroup) {
        case SystemKind.Database:
            return "Database";
        case SystemKind.DevOps:
            return "DevOps";
        case SystemKind.MSDataverse:
            return "MS Dataverse";
        default:
            return systemGroup;
    }
};
export { getSystemGroups, getFormattedGroupName };
