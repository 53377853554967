import { acquireApiToken } from "../../lib/msal-browser/authentication/authAPI";
import { fetchWithRetry } from "./utils/fetchWithRetry";
import { apiConfig } from "./config/apiConfig";
/**
 * Get token for the API
 * @returns MSAL Token
 */
const getAuthAPIToken = async function () {
    const tokenResponse = await acquireApiToken();
    return tokenResponse.accessToken;
};
/**
 * Make a request to the API to upload job definitions
 * @param authTokenFn Authorization token function
 * @param tenantIdOrKey Tenant ID or Key
 * @param siteIdOrName Site ID or Name
 * @param body FormData with the files to upload
 * @returns Response
 */
const fetchUploadJobsDefinitions = async function (tenantIdOrKey, siteIdOrName, body) {
    const authToken = await getAuthAPIToken();
    const headers = new Headers({
        Authorization: `Bearer ${authToken}`,
    });
    const config = {
        method: "POST",
        headers: headers,
        body,
    };
    return await fetchWithRetry(`${apiConfig.apiEndPoint}/${apiConfig.rest}/jobdefinitions/${tenantIdOrKey}/${siteIdOrName}/upload`, config);
};
/**
 * Make a request to the Graphql API
 * @param body Graphql query
 * @returns Response
 */
const fetchGraphql = async (body) => {
    const authToken = await getAuthAPIToken();
    const response = await fetchWithRetry(`${apiConfig.apiEndPoint}/${apiConfig.graphQL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/graphql-response+json",
            Authorization: `Bearer ${authToken}`,
        },
        body: body,
    });
    return await response.json();
};
export { fetchGraphql, fetchUploadJobsDefinitions };
