import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Spinner, mergeClasses, makeStyles, tokens, shorthands } from "@fluentui/react-components";
import { Add20Regular } from "@fluentui/react-icons";
const useStyles = makeStyles({
    addButton: {
        boxShadow: tokens.shadow4,
        "> button": {
            ...shorthands.border("0"),
        },
    },
    addSelectionDisabled: {
        cursor: "not-allowed",
    },
});
const AddSelectionButton = ({ disabled, spinner, numberSelectedPrincipals, onClick, }) => {
    const styles = useStyles();
    const buttonText = `Add Selection ${numberSelectedPrincipals > 0 ? `(${numberSelectedPrincipals})` : ""}`;
    return (_jsx("div", { className: mergeClasses(styles.addButton, disabled && styles.addSelectionDisabled), children: _jsx(Button, { disabled: disabled, appearance: "primary", icon: spinner ? _jsx(Spinner, { size: "tiny" }) : _jsx(Add20Regular, {}), onClick: onClick, children: _jsx("span", { children: buttonText }) }) }));
};
export default AddSelectionButton;
