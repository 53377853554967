import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, makeStyles, mergeClasses, MessageBar, MessageBarBody, MessageBarTitle, ProgressBar, shorthands, } from "@fluentui/react-components";
import { details as useGeneralStyles } from "@styles";
;
import InputDescription from "./InputDescription";
import InputName from "./InputName";
import { gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { addSite, updateSite, useDispatch } from "@lib/redux";
const useStyles = makeStyles({
    root: {
        ...shorthands.padding("1rem"),
        boxSizing: "border-box",
        height: "100%",
    },
    footer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "1rem",
        columnGap: "1rem",
    },
    messageRoot: {
        flexGrow: 2,
        display: "flex",
        alignItems: "flex-end",
        width: "30rem",
        justifyContent: "space-around",
        ...shorthands.overflow("hidden"),
    },
    messageWrap: {
        whiteSpace: "break-spaces",
    },
});
const ADD_SITE = gql `
  mutation CreateSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      site {
        id
        name
        description
      }
    }
  }
`;
const UPDATE_SITE = gql `
  mutation updateSite($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      site {
        id
        name
        description
      }
    }
  }
`;
const SiteForm = ({ editDescription, editName, onCancel, type }) => {
    const generalStyles = useGeneralStyles();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { tenantIdOrKey } = useParams();
    const [name, setName] = useState(type === "edit" ? editName : "");
    const [description, setDescription] = useState(type === "edit" ? editDescription : "");
    const [message, setMessage] = useState(null);
    const [validName, setValidName] = useState(type === "edit");
    const [disableButton, setDisableButton] = useState(false);
    const [createMutation, { data, loading, error, client, reset }] = useMutation(type === "add" ? ADD_SITE : UPDATE_SITE);
    const handleOnClick = () => {
        setDisableButton(true);
        if (type === "add") {
            createMutation({
                variables: {
                    input: {
                        tenantIdOrKey,
                        name,
                        description,
                    },
                },
            });
        }
        else {
            createMutation({
                variables: {
                    input: {
                        tenantIdOrKey: tenantIdOrKey,
                        siteIdOrName: editName,
                        newName: name,
                        newDescription: description,
                    },
                },
            });
        }
    };
    const handleOnChange = (value, isValid) => {
        setName(value);
        setValidName(isValid);
        const changeSiteNameAlert = "Editing the site name can possibly lead to API calls, that explicitly use the site name, also having to be adapted. We recommend using the site ID instead of the name in connection with our API to avoid this.";
        if (type === "edit") {
            if (value !== editName) {
                setMessage(_jsx(MessageBar, { intent: "warning", className: styles.messageWrap, "data-testid": "site-form-edit-change-name-message", children: _jsx(MessageBarBody, { children: changeSiteNameAlert }) }));
            }
            else {
                setMessage(null);
            }
        }
    };
    useEffect(() => {
        if (loading) {
            setMessage(_jsx(ProgressBar, { max: 42 }));
        }
    }, [loading]);
    useEffect(() => {
        if (error) {
            setDisableButton(false);
            setMessage(_jsx(MessageBar, { intent: "error", className: styles.messageWrap, children: _jsxs(MessageBarBody, { children: [_jsx(MessageBarTitle, { children: "Add error" }), error.message] }) }));
        }
    }, [error]);
    useEffect(() => {
        if (data) {
            if (type === "add" && data && data["createSite"] && data["createSite"]["site"]["id"]) {
                dispatch(addSite(data["createSite"]["site"]));
                reset();
                client.refetchQueries({ include: ["getSites"] });
                setName("");
                setDescription("");
                onCancel();
            }
            else if (type === "edit" && data && data["updateSite"] && data["updateSite"]["site"]) {
                dispatch(updateSite(data["updateSite"]["site"]));
                reset();
                client.refetchQueries({ include: ["getSites"] });
                setName("");
                setDescription("");
                onCancel();
            }
        }
    }, [data]);
    return (_jsxs("div", { className: mergeClasses(generalStyles.root, styles.root), "data-testid": "site-form", children: [_jsx(InputName, { value: name, onChange: handleOnChange, type: type, initialValue: editName }), _jsx(InputDescription, { value: description, onChange: (value) => setDescription(value) }), _jsx("div", { className: styles.messageRoot, children: message }), _jsxs("div", { className: styles.footer, children: [_jsx(Button, { disabled: !name || !validName || disableButton || (name === editName && description === editDescription), appearance: "primary", onClick: handleOnClick, "data-testid": "site-form-primary-button", children: type === "add" ? "Add" : "Save" }), _jsx(Button, { onClick: onCancel, "data-testid": "site-form-cancel-button", children: "Cancel" })] })] }));
};
export default SiteForm;
