import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Image, Spinner, Text, makeStyles, shorthands } from "@fluentui/react-components";
import { rootPage } from "@styles";
;
import welcomeImage from "../assets/welcome.svg";
import { useSelector } from "@lib/redux";
const useStyles = makeStyles({
    image: {
        maxWidth: "53.125rem",
        ...shorthands.padding("min(3vw, 0.625rem)", "3vw"),
        ...shorthands.margin("auto"),
    },
    message: {
        ...shorthands.overflow("visible"),
        width: "100%",
        display: "block",
    },
});
const WelcomeName = ({ name }) => {
    const styles = useStyles();
    const rootStyles = rootPage();
    const tenant = useSelector((state) => state.tenant);
    return (_jsx("div", { "data-testid": "welcome", className: rootStyles.root, children: _jsx("div", { className: rootStyles.body, children: !tenant.key ? (_jsx(Spinner, { label: "Loading...", size: "huge", className: rootStyles.spinner })) : (_jsxs(_Fragment, { children: [_jsx(Text, { "data-testid": "welcome-message", size: 900, align: "center", truncate: true, wrap: false, className: styles.message, children: `Welcome ${name}` }), _jsx("div", { className: styles.image, children: _jsx(Image, { "data-testid": "welcome-image", src: welcomeImage, fit: "contain" }) })] })) }) }));
};
export default WelcomeName;
