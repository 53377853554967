var _a;
const apiConfig = {
    apiEndPoint: process.env.REACT_APP_URLAPI || window.location.origin,
    rest: "_api/rest/v1",
    graphQL: "_api/graphql",
    scopes: [(_a = process.env.REACT_APP_AZUREAD_SCOPEAPI) !== null && _a !== void 0 ? _a : ""],
    readBasicScope: ["User.ReadBasic.All"],
    readApplicationScope: ["Application.Read.All"],
};
export { apiConfig };
