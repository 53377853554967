import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Subtitle2Stronger } from "@fluentui/react-components";
import { details as useStyles } from "@styles";
;
import { formatDate } from "../../utils/gatewayUtils";
const Status = ({ gateway }) => {
    const styles = useStyles();
    return (_jsxs("div", { className: styles.section, "data-testid": "gateway-details-status", children: [_jsx(Subtitle2Stronger, { className: styles.sectionTitle, children: "Status" }), _jsxs("div", { className: styles.row, children: [_jsx("div", { className: styles.rowItemPropertyName, children: "Status" }), _jsx("div", { children: gateway.state })] }), _jsx(Divider, {}), _jsxs("div", { className: styles.row, children: [_jsx("div", { className: styles.rowItemPropertyName, children: "Last Update" }), _jsx("div", { children: formatDate(gateway.lastUpdated) })] }), _jsx(Divider, {})] }));
};
export default Status;
