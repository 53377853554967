import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@lib/apolloClient";
import { Route, Routes, useParams } from "react-router-dom";
import { Spinner, makeStyles } from "@fluentui/react-components";
import { shorthands } from "@fluentui/react-components";
import { resetTenant, setSelectedSite, setTenant, useDispatch, useSelector } from "@lib/redux";
import { AppStateError, ErrorDisplay, mapApolloErrorToErrorMessage } from "@features";
import { Home, Settings } from "@pages";
const useStyles = makeStyles({
    spinner: {
        ...shorthands.margin("22%", "47%"),
    },
});
const GET_TENANT = gql `
  query getTenant($tenantIdOrKey: String!) {
    tenant(tenantIdOrKey: $tenantIdOrKey) {
      azureTenants
      id
      key
      name
      sites {
        id
        name
      }
    }
  }
`;
const PageContainer = () => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const { tenantIdOrKey } = useParams();
    const appStateError = useSelector((state) => state.appState.error);
    const userInfo = useSelector((state) => state.userInfo);
    const { loading, error, data } = useQuery(GET_TENANT, {
        variables: { tenantIdOrKey },
        onCompleted: (data) => {
            dispatch(setTenant(data.tenant));
            dispatch(setSelectedSite(data.tenant.sites[0]));
        },
        onError: () => {
            dispatch(resetTenant());
        },
    });
    if (appStateError) {
        return _jsx(AppStateError, {});
    }
    else if (error) {
        return _jsx(ErrorDisplay, { error: mapApolloErrorToErrorMessage(error) });
    }
    else if (loading || userInfo.displayName === "") {
        return _jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner });
    }
    else if (data) {
        return (_jsxs(Routes, { children: [_jsx(Route, { path: "settings/*", element: _jsx(Settings, {}) }), _jsx(Route, { path: "/*", element: _jsx(Home, {}) })] }));
    }
};
export default PageContainer;
