import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Title1 } from "@fluentui/react-components";
import { Delete20Regular, Eye20Regular, Pen20Regular } from "@fluentui/react-icons";
import { useUserRoles } from "@hooks";
import { Card } from "@components";
import { useSelector } from "@lib/redux";
import { rootPage as useRootStyles, cardsBody as useStyles } from "@styles";
;
import SiteCardBody from "./SiteCardBody";
const SitesCards = ({ sites, openDetailsView, openEditPanel, openDeletePanel }) => {
    const styles = useStyles();
    const rootStyles = useRootStyles();
    const { tenantAdmin } = useUserRoles();
    const onClickOpenDetailsView = (site) => {
        openDetailsView(site);
    };
    const onClickOpenEditPanel = (site) => {
        openEditPanel(site);
    };
    const reduxSites = useSelector((state) => state.tenant.sites);
    const menuCard = (site) => {
        const menuItems = [
            {
                key: "site-menu-1",
                name: "Details",
                icon: _jsx(Eye20Regular, {}),
                onClick: () => {
                    onClickOpenDetailsView(site);
                },
            },
        ];
        if (tenantAdmin) {
            menuItems.push({
                key: "site-menu-2",
                name: "Edit",
                icon: _jsx(Pen20Regular, {}),
                onClick: () => {
                    onClickOpenEditPanel(site);
                },
            });
            menuItems.push({
                key: "site-menu-3",
                name: "Delete",
                icon: _jsx(Delete20Regular, {}),
                onClick: () => {
                    openDeletePanel(site);
                },
            });
        }
        return menuItems;
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: rootStyles.wrapper, children: [_jsx(Title1, { className: rootStyles.pageTitle, children: "Sites" }), _jsx("div", { className: styles.container, children: sites
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((site) => {
                        var _a;
                        return (_jsx("div", { className: styles.cardContainer, children: _jsx(Card, { headerText: site.name, headerBadge: true, onClickHeader: () => {
                                    onClickOpenDetailsView(site);
                                }, menuItems: menuCard(site), isNew: (_a = reduxSites === null || reduxSites === void 0 ? void 0 : reduxSites.find((s) => s.id === site.id)) === null || _a === void 0 ? void 0 : _a.isNew, children: _jsx(SiteCardBody, { site: site }) }) }, site.id));
                    }) })] }) }));
};
export default SitesCards;
