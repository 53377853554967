import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, makeStyles, Switch } from "@fluentui/react-components";
import { Search12Regular } from "@fluentui/react-icons";
import { FadeRelaxed } from "@fluentui/react-motion-components-preview";
const useStyles = makeStyles({
    findUserContainer: {
        display: "flex",
    },
    findPrincipals: {
        width: "22rem",
    },
});
const FindUsers = ({ setShowSelectedPrincipals, onChangeSearchValue, visible, value, showSelectedValue, }) => {
    const styles = useStyles();
    return (_jsxs("div", { className: styles.findUserContainer, children: [_jsx(Input, { className: styles.findPrincipals, placeholder: "Find Users", contentBefore: _jsx(Search12Regular, {}), onChange: onChangeSearchValue, type: "search", value: value }), _jsx(FadeRelaxed, { visible: visible, children: _jsx("div", { children: _jsx(Switch, { label: "Show selected", checked: showSelectedValue, onChange: (_, data) => setShowSelectedPrincipals(data.checked) }) }) })] }));
};
export default FindUsers;
