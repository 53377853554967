import { jsx as _jsx } from "react/jsx-runtime";
import { useId, Toaster, useToastController } from "@fluentui/react-components";
import MessageToast from "./MessageToast";
/**
 * Custom hook to dispatch message toasts.
 *
 * @param prefix - A string or number to prefix the toaster message ID.
 * @returns An object containing:
 * - `dispatchMessageToast`: A function to dispatch a message toast with a specified message and severity.
 * - `dismissToast`: A function to dismiss a specific toast.
 * - `dismissAllToasts`: A function to dismiss all toasts.
 * - `toasterElement`: A JSX element representing the toaster component - renders a collection of toasts dispatched imperatively.
 * - `toasterMessageId`: The unique ID for the toaster message.
 */
export const useDispatchMessageToast = (prefix) => {
    const toasterMessageId = useId("message-toast-" + prefix);
    const { dispatchToast, dismissToast, dismissAllToasts } = useToastController(toasterMessageId);
    const toasterElement = _jsx(Toaster, { toasterId: toasterMessageId });
    const dispatchMessageToast = (message, severity) => {
        const messageToast = _jsx(MessageToast, { message: message, severity: severity });
        dispatchToast(messageToast, { timeout: 4000, position: "bottom" });
    };
    return { dispatchMessageToast, dismissToast, dismissAllToasts, toasterElement, toasterMessageId };
};
