import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Subtitle1 } from "@fluentui/react-components";
import { emptyBody as useStyles } from "@styles";
;
import emptyGatewaysImage from "../assets/emptyGateways.svg";
const NoGateways = () => {
    const styles = useStyles();
    return (_jsxs("div", { className: styles.emptyContainer, "data-testid": "systems-empty", children: [_jsx("img", { className: styles.image, src: emptyGatewaysImage, alt: "No Systems", "data-testid": "gateways-empty-image" }), _jsx(Subtitle1, { "data-testid": "systems-empty-message", children: "You don't have any Gateways." })] }));
};
export default NoGateways;
