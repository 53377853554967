import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@fluentui/react-components";
import Role from "./Role";
import AddRolesButton from "./AddRolesButton";
const useStyles = makeStyles({
    container: {
        display: "flex",
        columnGap: "0.3rem",
    },
});
const sortRoles = (roles) => {
    return [...roles.sort((a, b) => a.roleId - b.roleId)];
};
const SiteRoles = ({ roles, siteId, principalId, dispatchMessage }) => {
    const styles = useStyles();
    const [sortedRoles, setSortedRoles] = useState([]);
    const [rolesDeleted, setRolesDeleted] = useState(false);
    const prevSiteId = useRef(null);
    const deleteRole = (roleId) => {
        setSortedRoles(sortedRoles.filter((role) => role.roleId !== roleId));
        setRolesDeleted(true);
    };
    const addRole = (role) => {
        setSortedRoles(sortRoles([...sortedRoles, role]));
    };
    useEffect(() => {
        setSortedRoles(sortRoles(roles.filter((role) => role.siteId === siteId)));
    }, [roles, siteId]);
    useEffect(() => {
        if (rolesDeleted && prevSiteId.current !== siteId) {
            // #TODO refetch only if site or tab changed
            // refetch();
            setRolesDeleted(false);
            prevSiteId.current = siteId;
        }
    }, [rolesDeleted]);
    return (_jsxs("div", { "data-testid": "role-cell-item", className: styles.container, children: [sortedRoles.map((role) => (_jsx(Role, { role: role, dispatchMessage: dispatchMessage, onDelete: deleteRole }, role.roleId))), _jsx(AddRolesButton, { roles: sortedRoles, onAdd: addRole, principalId: principalId, siteId: siteId, dispatchMessage: dispatchMessage })] }));
};
export default SiteRoles;
