import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Toast, MessageBar, MessageBarBody, makeStyles, shorthands, Button, MessageBarActions, ToastTrigger, } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
const useStyles = makeStyles({
    messageToast: {
        width: "min-content",
        height: "min-content",
        ...shorthands.padding("0"),
    },
});
const MessageToast = ({ message, severity }) => {
    const styles = useStyles();
    return (_jsx(Toast, { className: styles.messageToast, children: _jsxs(MessageBar, { intent: severity, children: [_jsx(MessageBarBody, { children: message }), _jsx(MessageBarActions, { containerAction: _jsx(ToastTrigger, { children: _jsx(Button, { "aria-label": "dismiss", appearance: "transparent", icon: _jsx(DismissRegular, {}) }) }) })] }) }));
};
export default MessageToast;
