import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { makeStyles, mergeClasses, shorthands, Subtitle2Stronger, Divider } from "@fluentui/react-components";
import { details as useDetailsStyles } from "@styles";
;
import { SystemIcon } from "../../../systems";
const useStyles = makeStyles({
    ellipsis: {
        ...shorthands.overflow("hidden"),
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        flexGrow: 1,
        paddingLeft: "0.5rem",
    },
    row: {
        alignItems: "center",
    },
});
const SystemLinks = ({ systemLinks }) => {
    const detailsStyles = useDetailsStyles();
    const styles = useStyles();
    const noSystemLinks = "No system links";
    // Sort systemLinks by systemType and alias
    const sortedSystemLinks = [...systemLinks].sort((a, b) => {
        if (a.systemType < b.systemType)
            return -1;
        if (a.systemType > b.systemType)
            return 1;
        if (a.alias.toLowerCase() < b.alias.toLowerCase())
            return -1;
        if (a.alias.toLowerCase() > b.alias.toLowerCase())
            return 1;
        return 0;
    });
    return (_jsxs("div", { className: detailsStyles.section, "data-testid": "site-details-system-links", children: [_jsx(Subtitle2Stronger, { className: detailsStyles.sectionTitle, children: "System Links" }), systemLinks.length === 0 ? (_jsx("div", { className: detailsStyles.row, children: noSystemLinks })) : (sortedSystemLinks.map((systemLink) => (_jsxs(Fragment, { children: [_jsxs("div", { className: mergeClasses(detailsStyles.row, styles.row), children: [_jsx(SystemIcon, { type: systemLink.systemType }), _jsx("div", { className: mergeClasses(detailsStyles.rowItemPropertyName, styles.ellipsis), children: systemLink.alias })] }), _jsx(Divider, {})] }, systemLink.id))))] }));
};
export default SystemLinks;
