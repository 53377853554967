import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { details as useStyles } from "@styles";
;
import Main from "./Main";
import Status from "./Status";
import Created from "./Created";
import ConnectedSystems from "./ConnectedSystems";
import { useUserRoles } from "@hooks";
const GatewayDetail = ({ gateway, viaGateway }) => {
    const styles = useStyles();
    const { tenantAdmin } = useUserRoles();
    return (_jsxs("div", { className: styles.root, children: [_jsx(Main, { gateway: gateway, viaGateway: viaGateway }), _jsx(Status, { gateway: gateway }), tenantAdmin && _jsx(Created, { gateway: gateway }), !viaGateway && _jsx(ConnectedSystems, { connectedSystems: gateway.connectedSystems })] }));
};
export default GatewayDetail;
