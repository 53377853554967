import { fetchGraphql } from "@services/api/api";
const GET_ME = `
  query me($tenantIdOrKey: String!) {
    me {
      displayName
      principalId
      roleAssignments(tenantIdOrKey: $tenantIdOrKey) {
        principalId
        roleId
        siteId
        tenantId
      }
    }
  }
`;
/**
 * Fetch user information from the GraphQL API.
 *
 * Note:
 * - Although Apollo Client is our standard for GraphQL requests, it is not used here.
 *   This is because the data needs to be fetched within the Header component and
 *   stored in the Redux store. Apollo Client's caching behavior is not well-suited
 *   for this use case.
 *
 * @param tenantIdOrKey - The Tenant ID or Key.
 * @returns The GraphQL API response.
 */
const getMeInfo = async function (tenantIdOrKey) {
    const body = JSON.stringify({
        query: GET_ME,
        variables: { tenantIdOrKey },
    });
    return fetchGraphql(body);
};
export { getMeInfo };
