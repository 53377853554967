import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Persona, Subtitle2Stronger } from "@fluentui/react-components";
import { details as useStyles } from "@styles";
;
import { formatDate } from "../../utils/gatewayUtils";
const Created = ({ gateway }) => {
    const styles = useStyles();
    return (_jsxs("div", { className: styles.section, "data-testid": "gateway-details-created", children: [_jsx(Subtitle2Stronger, { className: styles.sectionTitle, children: "Created" }), _jsxs("div", { className: styles.row, children: [_jsx("div", { className: styles.rowItemPropertyName, children: "By" }), _jsx(Persona, { className: styles.persona, size: "extra-small", name: gateway.createdByDisplayName, avatar: { color: "colorful" } })] }), _jsx(Divider, {}), _jsxs("div", { className: styles.row, children: [_jsx("div", { className: styles.rowItemPropertyName, children: "At" }), _jsx("div", { children: formatDate(gateway.createdAt) })] }), _jsx(Divider, {})] }));
};
export default Created;
